import * as Sentry from "@sentry/browser";
import { debounce } from "lodash";
import {
	ConcreteRequest,
	ConnectionHandler,
	ConnectionInterface,
	DefaultHandlerProvider,
	Environment,
	FetchFunction,
	fetchQuery,
	generateClientID,
	HandleFieldPayload,
	Network,
	RecordSource,
	RecordSourceProxy,
	RequestParameters,
	Store,
} from "relay-runtime";
import { GraphQLResponse } from "relay-runtime/lib/network/RelayNetworkTypes";
import invariant from "tiny-invariant";

import { inCalendarMode, inVideocallMode } from "~/lib/utils";
import WebsocketClient, { GroupEmitter } from "~/lib/websocket";
import { validateVersion } from "~/lib/webUpdatePrompt";

import ActionItemsTabToggleCheckedMutation from "~/__generated__/ActionItemsTabToggleCheckedMutation.graphql";
import AskCopilotPanelSendMessageViaConversationMutation from "~/__generated__/AskCopilotPanelSendMessageViaConversationMutation.graphql";
import AskCopilotPanelSendMessageViaNoteMutation from "~/__generated__/AskCopilotPanelSendMessageViaNoteMutation.graphql";
import AssignableDropdownToggleBlocksFieldMutation from "~/__generated__/AssignableDropdownToggleBlocksFieldMutation.graphql";
import assignableToggleFieldDoneMutation from "~/__generated__/assignableToggleFieldDoneMutation.graphql";
import calendarPageParticipantEventsQuery from "~/__generated__/CalendarPageParticipantEventsQuery.graphql";
import homeQuery from "~/__generated__/HomeQuery.graphql";
import mutationsToggleBlockFieldMutation from "~/__generated__/mutationsToggleBlockFieldMutation.graphql";
import mutationsToggleBlocksFieldMutation from "~/__generated__/mutationsToggleBlocksFieldMutation.graphql";
import PublicMeetingRecapQuery from "~/__generated__/PublicMeetingRecapQuery.graphql";
import TranscriptModalPublicClipQuery from "~/__generated__/TranscriptModalPublicClipQuery.graphql";
import PublicGetClipURLQuery from "~/__generated__/utils_publicGetClipLinkQuery.graphql";
import PublicGetRecordingURLQuery from "~/__generated__/utils_publicGetRecordingLinkQuery.graphql";

const WS_MUTATIONS: readonly string[] = [
	AssignableDropdownToggleBlocksFieldMutation,
	ActionItemsTabToggleCheckedMutation,
	assignableToggleFieldDoneMutation,
	mutationsToggleBlockFieldMutation,
	mutationsToggleBlocksFieldMutation,

	calendarPageParticipantEventsQuery,

	AskCopilotPanelSendMessageViaConversationMutation,
	AskCopilotPanelSendMessageViaNoteMutation,
].map(req => req.params.name);

const QUERY_ENDPOINT_OVERRIDES: Readonly<{ [query: string]: string }> = {
	[PublicMeetingRecapQuery.params.name]: "/public/meeting_recordings/meeting_recording.json",
	[PublicGetRecordingURLQuery.params.name]: "/public/meeting_recordings/url/",
	[TranscriptModalPublicClipQuery.params.name]: "/public/clips/clip.json",
	[PublicGetClipURLQuery.params.name]: "/public/clips/url/",
};

export const relayCache: Map<string, GraphQLResponse> = new Map();
export const relaySource: RecordSource = new RecordSource();
export const relayStore: Store = new Store(relaySource);
export const NETWORK_RETRY_LOOP_EVENT = "network-retry-loop";

window.relayCache = relayCache;

// JS is single-threaded - no need for any fancy locking!
let inflight = 0;

const PREFETCHING_QUERIES: [query: ConcreteRequest, shouldPreloadPredicate?: () => Promise<boolean>][] = [
	// Nothing's gonna break if the predicate function is not 100% accurate
	// But it's less risky to have false negatives than false positives.
	[
		homeQuery,
		async () => {
			return !(inVideocallMode || inCalendarMode);
		},
	],
];

function prefetchQueries() {
	// this function prefetches queries before the correspondent components are being rendered.
	// we do this for some commonly used queries to make the app feels snappier
	if (!window.INITIAL_STATE.user) return;
	PREFETCHING_QUERIES.forEach(async ([query, shouldPreloadPredicate]) => {
		const shouldPreload = shouldPreloadPredicate ? await shouldPreloadPredicate() : true;
		shouldPreload &&
			fetchQuery(
				environment,
				query,
				{},
				{
					// using "store-or-network" here so that if the query is already run, don't try to cache it again
					fetchPolicy: "store-or-network",
				},
			).toPromise();
	});
}

document.addEventListener(
	"network-quiesced",
	() => {
		prefetchQueries();
	},
	{ once: true },
);

const networkQuiesced = debounce(() => {
	document.dispatchEvent(new CustomEvent("network-quiesced"));
}, 150);

type GQLFetchFn = (
	operation: RequestParameters,
	variables: unknown,
	onNetworkReturn?: () => void,
) => Promise<GraphQLResponse>;

export class NetworkError extends TypeError {}

const httpFetch: GQLFetchFn = async (operation, variables, onNetworkReturn) => {
	const maxRetryIterationsCount = 3;
	let response: Response | null = null;
	let lastError: unknown = null;

	let endpoint;
	if (operation.name in QUERY_ENDPOINT_OVERRIDES) {
		endpoint = QUERY_ENDPOINT_OVERRIDES[operation.name];
	} else {
		endpoint = window.GRAPHQL_ENDPOINT || `/graphql/`;
	}

	// try up to 3 times to fetch the data and wait 5 seconds between retries
	for (let retryIteration = 1; retryIteration <= maxRetryIterationsCount; retryIteration++) {
		try {
			response = await fetch(`${endpoint}?operation=${operation.name}`, {
				method: "POST",
				credentials: "same-origin",
				headers: {
					accept: "application/json",
					"content-type": "application/json",
					"x-client-session-id": window.CLIENT_SESSION_ID,
					"x-csrftoken": window.CSRF_TOKEN,
					"x-requested-with": "XMLHttpRequest",
				},
				body: JSON.stringify({
					query: operation.text,
					variables: variables,
				}),
			});
		} catch (e) {
			if (e instanceof TypeError) {
				// convert it to a NetworkError
				Object.setPrototypeOf(e, NetworkError.prototype);
				// In case you were concerned:
				invariant(e instanceof NetworkError);
			}
			lastError = e;
			response = null;
		}

		// If we got a response, break out of the loop.
		if (response !== null) {
			break;
		}

		// Dispatch an event so that the UI can show a message, but only after the first retry.
		if (retryIteration !== 1) {
			window.dispatchEvent(new CustomEvent(NETWORK_RETRY_LOOP_EVENT, { detail: { value: true } }));
		}

		// If this is not the last retry, wait 5 seconds before trying again.
		if (retryIteration !== maxRetryIterationsCount) {
			await new Promise(resolve => {
				// if we go online, cancel the timeout and resolve with null immediately
				const retryTimeoutId = setTimeout(() => {
					resolve(null);
					window.removeEventListener("online", clearRetryTimeout);
				}, 5000);
				const clearRetryTimeout = () => {
					clearTimeout(retryTimeoutId);
					resolve(null);
					window.removeEventListener("online", clearRetryTimeout);
				};
				window.addEventListener("online", clearRetryTimeout);
			});
		}
	}

	// There is no need to dispatch the NETWORK_RETRY_LOOP_EVENT event if the retry loop never failed.
	if (lastError !== null) {
		window.dispatchEvent(new CustomEvent(NETWORK_RETRY_LOOP_EVENT, { detail: { value: false } }));
	}

	if (!response) {
		throw lastError ?? new Error("Network error");
	}

	if (onNetworkReturn) {
		onNetworkReturn();
	}

	if (response.status == 403) {
		if (response.headers.get("X-Fellow-Login-Required") === "yes") {
			window.location.href = `/auth/login/?failed=1&next=${encodeURIComponent(window.location.pathname)}`;
		}
		return { errors: [{ message: "Network error" }] };
	}
	if (response.headers && response.headers.get("X-App-Version")) {
		validateVersion(response.headers.get("X-App-Version") as string, false);
	}
	const data = (await response.json()) as GraphQLResponse;

	return data;
};

let wsGroupEmitter: GroupEmitter | undefined;

function getGroupEmitter(): GroupEmitter {
	if (wsGroupEmitter) {
		return wsGroupEmitter;
	}
	return (wsGroupEmitter = WebsocketClient.getInstance().getOrJoinGroup(`gql.${window.INITIAL_STATE.user?.id}`));
}

const wsFetch: GQLFetchFn = async (operation, variables, onNetworkReturn, fallBackToHttp = true) => {
	const socket = getGroupEmitter();
	if ((!socket.online || socket.socketClient.socket?.readyState !== WebSocket.OPEN) && fallBackToHttp) {
		return httpFetch(operation, variables, onNetworkReturn);
	}
	const res = await socket.request<GraphQLResponse>({ query: operation.text, variables, type: "graphql_exec" });
	if (onNetworkReturn) {
		onNetworkReturn();
	}
	return res;
};

const getFetch = (operation: RequestParameters) => {
	if (WS_MUTATIONS.includes(operation.name)) {
		return wsFetch;
	}
	return httpFetch;
};

const relayFetchFn: FetchFunction = async (operation, variables, _cacheConfig, _uploadables) => {
	const shouldCache = [
		"UserSelectRenderer_Query",
		"UserSelectRefetchQuery",
		"DatePickerQuery",
		"UserPopupQuery",
		"ContactSearchRefetchQuery",
		"AttachmentPreviewQuery",
		"CalendarPageQuery",
	].includes(operation.name);

	// on deactivation clear the UserSelect cache so deactivated users don't show up in UserSelect
	if (operation.name == "UserRowEditUserUpsertUserMutation") {
		for (const [key] of relayCache.entries()) {
			if (key.startsWith("UserSelectRenderer_Query") || key.startsWith("UserSelectRefetchQuery")) {
				relayCache.delete(key);
			}
		}
	}

	if (operation.name == "UserPopupQuery" && variables.skip) {
		return { data: {} };
	}

	let cached = null;

	if (shouldCache) {
		cached = relayCache.get(`${operation.name}${JSON.stringify(variables)}`);

		if (cached) {
			return cached;
		}
	}

	const ignoreQuiesce = ["AppQuery"].includes(operation.name);

	if (!ignoreQuiesce) {
		inflight += 1;
		networkQuiesced.cancel();
	}

	const fetchFn = getFetch(operation);

	const data = await fetchFn(operation, variables, () => {
		if (!ignoreQuiesce) {
			inflight -= 1;
			if (inflight == 0) networkQuiesced();
		}
	});

	if (shouldCache) {
		relayCache.set(`${operation.name}${JSON.stringify(variables)}`, data);
	}
	if (window.SENTRY_DSN_PUBLIC && "errors" in data && data.errors && data.errors.length > 0) {
		Sentry.addBreadcrumb({
			category: "graphql-error",
			message: JSON.stringify(data.errors),
			level: "error" as Sentry.SeverityLevel,
		});
	}
	return data;
};

export const environment: Environment = new Environment({
	network: Network.create(relayFetchFn),
	store: relayStore,
	handlerProvider(handle: string) {
		if (handle !== "connection") {
			// see relay-runtime/handlers/RelayDefaultHandlerProvider.js
			return DefaultHandlerProvider(handle);
		}

		return {
			...ConnectionHandler,
			/**
			 * An extension of the default ConnectionHandler.update that supports
			 * pagination by ranges.
			 */
			update(store: RecordSourceProxy, payload: HandleFieldPayload): void {
				// This logic is borrowed from the implementation in
				// ConnectionHandler.update.
				const record = store.get(payload.dataID);
				if (!record) return;

				const { END_CURSOR, PAGE_INFO, START_CURSOR } = ConnectionInterface.get();

				const clientConnectionID = generateClientID(record.getDataID(), payload.handleKey);
				const clientConnectionField = record.getLinkedRecord(payload.handleKey);
				const clientConnection = clientConnectionField ?? store.get(clientConnectionID);
				const clientPageInfo = clientConnection && clientConnection.getLinkedRecord(PAGE_INFO);

				/* This logic is to enable fetching ranges of data i.e. passing before
				   _and_ after as arguments to a connection.
				   This is useful if you know the cursor after an object you want, since
				   you can just fetch up to that.

				   Without doing this stuff, this would only work if the range of data
				   comes _after_ the page we have already. */

				// if we already have some data for this connection
				if (clientPageInfo) {
					// get the start and end cursor for the data we already have
					const startCursor = clientPageInfo.getValue(START_CURSOR);
					const endCursor = clientPageInfo.getValue(END_CURSOR);

					// if the data we just got ends just before the data we already have,
					// remove the "after" argument
					if (startCursor != null && startCursor === payload.args.before) {
						delete payload.args.after;
					}
					// if the data starts after what we already have, delete the before
					// cursor
					if (endCursor != null && endCursor === payload.args.after) {
						delete payload.args.before;
					}
				}

				ConnectionHandler.update(store, payload);
			},
		};
	},
});
